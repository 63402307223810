<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        AI HR Writer
      </h2>

      <img
        src="../../assets/img/predictive_header.jpg"
        alt="AI HR Writer Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >
      <p class="pt-8 pb-4">
        Are you tired of spending countless hours crafting engaging HR content?
        Imagine having a powerful assistant by your side, creating compelling
        HR materials at the click of a button. Well, now you can with
        the revolutionary AI Content Generator!
      </p>
      <h4 class="pt-4 font-medium text-lg">
        🚀 Unlock Limitless HR Creativity:
      </h4>
      <p class="pt-2 pb-4">
        Gone are the days of struggling to come up with fresh, captivating
        HR content. MRC AI HR Writer unleashes the power of artificial intelligence
        to generate high-quality HR materials that captivate your audience,
        leaving them begging for more.
      </p>
      <h4 class="pt-4 font-medium text-lg">
        ⏰ Save Time and Effort:
      </h4>
      <p class="pt-2 pb-4">
        We understand that time is your most valuable asset. MRC AI HR Writer
        is designed to be your trusted partner, ensuring you can focus on what
        truly matters—your people. Simply provide a few details, and within seconds,
        our advanced AI algorithms will generate top-notch HR content,
        saving you hours of brainstorming and editing.
      </p>
      <h4 class="pt-4 font-medium text-lg">
        🌐 Tailor-Made for Your Needs:
      </h4>
      <p class="pt-2 pb-2">
        Every organization has its unique style and tone. MRC AI HR Writer
        understands that, offering customizable options to match your brand voice
        effortlessly. Whether you prefer a professional and formal tone or a casual
        and friendly approach, our app adapts to your needs,
        ensuring your content resonates with your audience.
      </p>
      <p class="pt-2 pb-4">
        Unlock the power of AI and revolutionize your HR content creation process.
        Say goodbye to writer's block and hello to unparalleled creativity.
        Get MRC AI HR Writer and start crafting captivating HR content effortlessly.
        Get ready to amaze your employees and stakeholders alike!
      </p>
      <div class="flex justify-center">
        <div class="grid md:grid-cols-2 gap-6">
          <img
            style="height: 40rem"
            src="../../assets/img/aiwriter_generator1.jpg"
            alt="AI HR Writer Generator 2"
            class="object-cover sm:p-4 content-center"
          />
          <img
            style="height: 40rem"
            src="../../assets/img/aiwriter_generator2.jpg"
            alt="AI HR Writer Generator 1"
            class="object-cover sm:p-4 content-center"
          />
        </div>
      </div>
      <br/>
      <br/>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
